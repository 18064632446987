html, body {
  overflow-x: hidden;
}

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  position: relative;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 0px 20px 20px 20px;
}

.top-navbar {
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  width: 100%;
  margin-bottom: 0px;
}

.top-navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.top-navbar li {
  margin: 0 10px;
  transition: transform 0.3s ease;
}

.top-navbar a {
  text-decoration: none;
  color: #333;
}

.top-navbar li:hover {
  transform: translateY(-2px); /* Slight lift on hover */
}

.social-button {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row; /* Add this to line them up in a row */
}

.social-button img {
  max-width: 20px;
  max-height: 20px;
}

.social-button a {
  display: inline-flex; /* Use inline-flex to align icon and text */
  align-items: center; /* Align the items vertically */
  justify-content: center; /* Center content horizontally */
  margin: 5px 10px;
  text-decoration: none;
  color: #333;
  padding: 10px 20px; /* Adjust padding as needed */
  border: none; /* Remove border for this design */
  border-radius: 20px; /* Increase border-radius for rounded corners */
  background-color: #fff; /* White background */
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2); /* Drop shadow effect */
  transition: box-shadow 0.3s, transform 0.3s; /* Transition for hover effects */
}

.social-button a:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25); /* Increased shadow on hover */
  transform: translateY(-2px); /* Slight lift on hover */
}

.content {
  max-width: 800px;
  width: 100%;
  text-align: left;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 0px;
}

.name {
  margin-bottom: 10px;
}

.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-image {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
  max-width: 100%;
  margin: auto;
}

.image-grid img {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}

.color-heading  {
  color: #7991E7;
}

nav {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

nav a {
  margin: 5px 10px;
  text-decoration: none;
  color: #333;
}

section {
  margin-bottom: 30px;
}

ul {
  padding: 0 0 0 15px;
}

li {
  margin-bottom: 10px;
}

@media (max-width: 480px) {
  .profile-image {
    max-width: 300px;
  }

  .social-button {
    flex-wrap: nowrap; /* Prevent wrapping on small screens */
  }
  
  .social-button a {
    flex: 0 0 auto; /* Prevent buttons from growing or shrinking */
    margin: 8px; /* Adjust margin if necessary for spacing */
    padding: 12px;
    font-size: 14px;
  }
}